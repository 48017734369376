import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from '../services/global.service';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate {
  
  constructor(private global_service: GlobalService,private router: Router){}

  canActivate(): boolean{
    if(!this.global_service.isLogin()){
      return true;
    }else{
      console.log('kk')
      window.location.href = '/home'
      return false;
    }
  }
  
}
