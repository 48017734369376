import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  orderSuccess = new Subject<any>();

  constructor() {
    let clear = 'false';
    this.orderSuccess.next(clear)
   }
  fromRegPage:boolean=false
  // isSuperRecruiter:boolean=false
  removeMenu:boolean=true
  jobId:string=''
  applied:boolean=null
  srchData=[]
  homeSearch=[]
  comingFrom:string=null


  isLogin(){
    
    return !!sessionStorage.getItem('token')
  }
  
  autoLogOut(){
    setTimeout(() =>{
      this.logout()
    },30000)
  }

  logout() {
    sessionStorage.removeItem('token')
    localStorage.removeItem('userId')
    localStorage.removeItem('userpho')
    localStorage.removeItem('username')
    localStorage.removeItem('userlname')
    localStorage.removeItem('img')
    localStorage.removeItem('userCreds')
    localStorage.removeItem('recId')
    localStorage.removeItem('jobId')
    localStorage.removeItem('company_id')
    localStorage.removeItem('ExpDetails')
    localStorage.removeItem('toApply')
    localStorage.removeItem('recruiter')
    localStorage.removeItem('jobseeker')
    localStorage.clear()
    location.href = '/home'
  }
}
