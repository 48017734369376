import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ADDINTERESTEDJOB_URL,
  ADDAPPLIEDJOBS_URL,
  ADDJOBSEEKER_URL,
  GETALLSEEKERAJOBS_URL,
  GETALLSEEKERIJOBS_URL,
  GETJOBSEEKERBYEID,
  UPLOADCV_URL,
  GETSEEKERAJOBS_URL,
  GETSEEKERIJOBS_URL,
  GETJOBSEEKERBYJSID_URL,
  SENDJOBSALERT_URL,
  GETALLJOBSEEKERMESSAGEBYEID_URL,
  GETALLJOBSEEKERMESSAGEBYJPID_URL,
  UPLOADVDO_URL,
  ADDJOBALERT_URL,
  ADDJOBSEEKERAPPLYJOB_URL,
  EDITJOBSEEKER_URL,
  GETALLJOBALERTBYJSEMAIL_URL,
  unsubscribeAlertById_URL
} from '../url';

@Injectable({
  providedIn: 'root'
})
export class JobseekerService {

  fileData: string = ''
  fileData1:string=''
  get: any;
  // getAllJobSeekerByEId: any;
  constructor(public http: HttpClient) { }
  addJobSeeker(data) {
    return this.http.post(ADDJOBSEEKER_URL, data)
  }
  getJobSeekerByEId(id) {
    return this.http.get(GETJOBSEEKERBYEID + id)
  }
  
  // GET ALL JOB SEEKER MESSAGE

  getAllJobSeekerMsgByEId(id){
    return this.http.get(GETALLJOBSEEKERMESSAGEBYEID_URL + id)
  }
  
  // GET ALL JOB SEEKER MESSAGE BY JOBPOSTING ID

  getAllJobSeekerMsgByJPId(id){
    return this.http.get(GETALLJOBSEEKERMESSAGEBYJPID_URL + id)
  }

  addInterestedJobs(data) {
    return this.http.post(ADDINTERESTEDJOB_URL, data, {
      headers: new HttpHeaders(
        { "content-type": "application/json" }
      )
    })
  }
  addAppliedJobs(data) {
    return this.http.post(ADDJOBSEEKERAPPLYJOB_URL, data, {
      headers: new HttpHeaders(
        { "content-type": "application/json" }
      )
    })
  }
  getAllJobSeekerIJobs(id) {
    return this.http.get(GETALLSEEKERIJOBS_URL + id)
  }
  getAllJobSeekerAJobs(id) {
    return this.http.get(GETALLSEEKERAJOBS_URL + id)
  }
  uploadCv(file) {
    this.fileData = file
    const form: FormData = new FormData();
    form.append('file', file)
    // console.log(file)
    return this.http.post(UPLOADCV_URL, form)
  }
  uploadVdo(file, id) {
    this.fileData1 = file
    const form: FormData = new FormData();
    form.append('file', file)
    // console.log(file)
    return this.http.post(UPLOADVDO_URL + id, form)
  }
  
  getAllJSaJobs(id){
    return this.http.get(GETSEEKERAJOBS_URL+id)
  }
  getAllJSiJobs(id){
    return this.http.get(GETSEEKERIJOBS_URL+id)
  }
  getJobSeekerByJSID(id){
    return this.http.get(GETJOBSEEKERBYJSID_URL+id)
  }
  sendJobAlerts(email,id){
    return this.http.post(SENDJOBSALERT_URL+email+"&skillId="+id, {
      headers: new HttpHeaders(
        { "content-type": "application/json" }
      )
    })
  }
  addJobAlerts(data){
    return this.http.post(ADDJOBALERT_URL,data, {
      headers: new HttpHeaders(
        { "content-type": "application/json" }
      )
    })
  }
  editJobSeeker(data){
    return this.http.put(EDITJOBSEEKER_URL,data, {
      headers: new HttpHeaders(
        { "content-type": "application/json" }
      )
    })
  }
  getJobAlertsById(id){
    return this.http.get(GETALLJOBALERTBYJSEMAIL_URL+id)
  }

  unSubscribeAlertById(id){
    return this.http.delete(unsubscribeAlertById_URL+id)
  }


}
