export const DEV = 'https://dev.hykrsol.com/sacareersv3/'
export const ImageUrl = "https://dev.hykrsol.com/sacareers/download-file/sacareers.co.za.objectstore/";

export const PROD = 'https://dev.hykrsol.com/sacareers/'

export const BASE_URL = PROD
export const USERLOGIN_URL = BASE_URL + 'users/login'
export const ADDJOBRECRUITER_URL = BASE_URL + 'admin/CompanyRecruiter/addCompanyRecruiter'
export const GETALLJOBSPOSTED_URL = BASE_URL + 'admin/jobPosting/all'
export const GETALLJOBPOSTEDEDUCATIONALDETAILS_URL = BASE_URL + 'admin/jobPostingEducation/all'
export const GETAllJOBPOSTINGSKILL_URL = BASE_URL + 'admin/jobPostingSkill/all'
export const GETALLJOBLOCATIONS_URL = BASE_URL + 'v1/JobLocation/getalljobLocations'
export const ADDCOMPANYPROFILE_URL = BASE_URL + 'admin/CompanyProfile/addCompanyProfile'
export const INSERTUSER_URL = BASE_URL + 'users/insert'
export const GETCOMPANYDETAILSBYRECID = BASE_URL + 'admin/CompanyRecruiter/getCompanyDetailsbyRecruiterId/'
export const GETRECBYUSERID_URL = BASE_URL + 'admin/CompanyRecruiter/getCompanyRecruiterByUserId/'
export const GET_SUB_REC_URL = BASE_URL + 'admin/CompanyRecruiter/getAllSubRecruiterByCompanyId/'

export const JOBPOST_URL = BASE_URL + 'admin/jobPosting/insertForRecruiter'
export const GETJOBPOSTBTID_URL = BASE_URL + 'admin/jobPosting/getByCompanyId/'
export const GETALLJOBTITLE_URL = BASE_URL + 'admin/jobtitle/all'
export const GETALLSKILL_URL = BASE_URL + 'v1/Skill/allSkills'
export const GETALLLOCATIONS_URL = BASE_URL + 'v1/Location/getAllLocations'
export const GETALLEDU_URL = BASE_URL + 'admin/Education/getAllEducationDetails'
export const GETALLINDUSTRY_URL = BASE_URL + 'v1/Industry/getallindustries'
export const GETALLEXP_URL = BASE_URL + 'admin/Experience/getAllExperiencesDetails'
export const GETEXPBYID_URL = BASE_URL + 'admin/Experience/getExperienceDetailById/'
export const ADDJOBSEEKER_URL = BASE_URL + 'admin/JobSeeker/addJobSeeker'
export const EDITJOBSEEKER_URL=BASE_URL+'admin/JobSeeker/'
export const GETJOBSEEKERBYEID = BASE_URL + 'admin/JobSeeker/getJobSeekerByEmailId/'
export const UPLOADUSERIMAGE_URL = BASE_URL + 'users/uploadSmallImage/'
export const UPLOADCOMPANYLOGO_URL = BASE_URL + 'admin/CompanyProfile/uploadCompanyLogo/'
export const GETJOBPOSTEDBYID_URL = BASE_URL + 'admin/jobPosting/'
export const GETCOMPANYBYID_URL = BASE_URL + 'admin/CompanyProfile/getCompanyProfileById/'
export const GETALLNOTICEPERIOD_URL = BASE_URL + 'v1/NoticePeriod/getAllNoticePeriods'
export const GETALLLANGUAGE_URL = BASE_URL + 'v1/Language/allLanguages'
export const GETALLLANGLEVEL_URL = BASE_URL + 'v1/LanguageLevel/allLanguages'
export const ADDINTERESTEDJOB_URL = BASE_URL + 'interestedJobs/addSeekerInterestedJob'
export const ADDAPPLIEDJOBS_URL = BASE_URL + 'appliedJobs/addSeekerAppliedJob'
export const GETALLSEEKERAJOBS_URL = BASE_URL + 'admin/CompanyRecruiter/getAppliedJobSeekerByJobPostingId/'
export const GETALLSEEKERIJOBS_URL = BASE_URL + 'admin/CompanyRecruiter/getInterestedJobSeekerByJobPostingId/'
export const SOFTDELRECBID_URL = BASE_URL + 'admin/CompanyRecruiter/softDeleted/'
export const HARDDELRECBID_URL = BASE_URL + 'admin/CompanyRecruiter/hardDelete/'


export const EDITCOMPREC_URL = BASE_URL + 'admin/CompanyRecruiter/'
export const EDITCOMPANYPROF_URL = BASE_URL + 'admin/CompanyProfile/'
export const SOFTDELJOBPOSTED_URL = BASE_URL + 'admin/jobPosting/softDeleted/'
export const HARDDELJOBPOSTED_URL = BASE_URL + 'admin/jobPosting/hardDelete/'

export const UPLOADCV_URL = BASE_URL + 'admin/JobSeeker/uploadCV/'
export const SEARCHJOBSBYSKILL_URL = BASE_URL + 'admin/jobPosting/searchJobsBySkills'
export const SEARCHJOBBYLOC_URL = BASE_URL + 'admin/jobPosting/searchJobsByLocations'
export const SEARCHJOBBYSAL_URL = BASE_URL + 'admin/jobPosting/searchJobsBySalaryRange'
export const SEARCHJOBBYEXP_URL = BASE_URL + 'admin/jobPosting/searchJobsByExperience'
export const SEARCHJOBBYINDUSTRY_URL = BASE_URL + 'admin/jobPosting/searchJobsByIndustries'
export const HOMEPAGECOMBOSEARCH_URL = BASE_URL + 'admin/jobPosting/searchJobsBySkillAndLocationAndIndustryAndTitle'
export const EDITJOBPOSTING_URL = BASE_URL + 'admin/jobPosting/updateForRecruiter/'
export const GETSEEKERAJOBS_URL = BASE_URL + 'appliedJobs/getAllSeekerAppliedJobsByUserId/'
export const GETSEEKERIJOBS_URL = BASE_URL + 'interestedJobs/getAllSeekerInterestedJobsByUserId/'
export const GETJOBSEEKERBYJSID_URL = BASE_URL + 'admin/JobSeeker/getJobSeekerById/'
export const SENDJOBSALERT_URL = BASE_URL + 'admin/JobSeeker/setJobAlert?jobSeekerEmailId='
export const POSTJSMESSAGE_URL = BASE_URL + 'JobSeekerMessage/addJobSeekerMessage'
export const GETALLJOBSEEKERMESSAGEBYEID_URL = BASE_URL + 'JobSeekerMessage/getAllJobSeekerMessagesByEmail/{emailId}?emailId='
export const GETALLJOBSEEKERMESSAGEBYJPID_URL = BASE_URL + 'JobSeekerMessage/getAllJobSeekerMessagesByJobPostingId/{jobPostingId}?jobPostingId='
export const UPLOADVDO_URL=BASE_URL+'admin/JobSeeker/uploadVideoClip/'
export const FORGOTPASS_URL=BASE_URL+'users/sendForgotPasswordLink/'
export const WILDSEARCHTITLE_URL=BASE_URL+'admin/jobtitle/getByName/'
export const GETEDUBYID_URL=BASE_URL+'admin/Education/getEducationDetailById/'
export const SKILL_AND_JOBTITLE_URL=BASE_URL+'admin/jobtitle/getSkillAndTitleByName/'
export const ADDSKILL_URL=BASE_URL+'v1/Skill/insertSkill'
export const ADDLOCATION_URL=BASE_URL+'v1/Location/addLocation'
export const ADDJOBALERT_URL=BASE_URL+'admin/JobSeeker/setJobAlert'
export const ADDJOBSEEKERAPPLYJOB_URL=BASE_URL+'appliedJobs/addSeekerAppliedJob'
export const GETNOTICEPERIODBYID_URL=BASE_URL+'v1/NoticePeriod/getNoticePeriodsById/'
export const GETALLJOBALERTBYJSEMAIL_URL=BASE_URL+'admin/JobSeeker/getAllJobAlertBySeekerEmailId/'
export const GETAllSEEKERBYJOBPOSTINGID_URL = BASE_URL + 'appliedJobs/getAllSeekerByJobPostingId/'
export const GETAllJOSEEKER_URL = BASE_URL + 'admin/JobSeeker/getAllJobSeekers/'
export const SEARCHJOBBYJOBTYPES_URL=BASE_URL+'admin/jobPosting/searchByJobType'
export const GETSALARYBYINPUTRANGES_URL=BASE_URL+'admin/jobPosting/searchJobsBySalaryRanges'
export const GETJOBBYET_URL=BASE_URL+'admin/jobPosting/searchByEmploymentType'
export const GETJOBBYDATE_URL=BASE_URL+'admin/jobPosting/searchByJobPostedDate'
export const GETJOBSEARCHBYJOBTITLE_URL=BASE_URL+'admin/jobPosting/searchByJobTitle'
export const GETJOBSEARCHJOBSBBYINDUSTRIES_URL=BASE_URL+'admin/jobPosting/searchJobsByIndustries'
export const GETJOBSEARCHJOBSBYLOCATIONS_URL=BASE_URL+'admin/jobPosting/searchJobsByLocations'


export const searchSkillsByNameLike_URL=BASE_URL+'v1/Skill/searchSkillsByNameLike/'
export const SkillGetById_URL=BASE_URL+'v1/Skill/getById/'
export const LanguagesById_URL=BASE_URL+'v1/Language/LanguagesById/'
export const getLocationsById_URL=BASE_URL+'v1/Location/getLocationsById/'

export const addJobTitle_URL=BASE_URL+'admin/jobtitle'
export const addIndustry_URL=BASE_URL+'v1/Industry/addindustry'
export const addEducation_URL=BASE_URL+'admin/Education/addEducation'
export const deleteInterestedJobs_URL=BASE_URL+'interestedJobs/hardDeleteByUserIdAndJobPostingId/'
export const updateRec_URL=BASE_URL+'admin/CompanyRecruiter/'
export const changePassword_URL=BASE_URL+'users/resetPassword'
export const userHardDelete_URL=BASE_URL+'users/hardDeleteByIdOrEmailId/'
export const addJobSeeker_URL=BASE_URL+'admin/JobSeeker/addJobSeeker'
export const getJobPostsByEmailId_URL=BASE_URL+'admin/jobPosting/getByEmailId/'
export const getJobSeekersBySkillIds_URL=BASE_URL+'admin/JobSeeker/getJobSeekersBySkillIds?'
export const unsubscribeAlertById_URL=BASE_URL+'admin/JobSeeker/unsubscribeAlertById/{id}?id='
export const forgotPasswordThroughLink_URL=BASE_URL+'users/forgotPasswordThroughLink'
export const addQuery_URL = BASE_URL + 'generalQuery/addQuery'
export const getJobSeekersBySkillNames_URL = BASE_URL + 'admin/JobSeeker/getJobSeekersBySkillNames?skillNameSet='
export const getAllQuery_URL = BASE_URL + 'faq/getAllQuery'
export const DOWNLOADFILE_URL = BASE_URL+'download-file/downloadJobSeekerCVOrVideoFileByFilename/'
export const getAllCC_URL = BASE_URL+ 'admin/CountryCodeMaster/getAllCountryCodeMasters'
